// @flow
import 'regenerator-runtime/runtime.js';

// See: https://formatjs.io/docs/polyfills/intl-getcanonicallocales/#dynamic-import--capability-detection
import {
    shouldPolyfill as shouldPolyfillIntlGetcanonicallocales,
} from '@formatjs/intl-getcanonicallocales/should-polyfill';

async function polyfillIntlGetcanonicallocales() {
    if (shouldPolyfillIntlGetcanonicallocales()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }
}

// See: https://formatjs.io/docs/polyfills/intl-locale/#dynamic-import--capability-detection
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';

async function polyfillIntlLocale() {
    if (shouldPolyfillIntlLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }
}

// See: https://formatjs.io/docs/polyfills/intl-pluralrules/#dynamic-import--capability-detection
import { shouldPolyfill as shouldPolyfillIntlPluralrules } from '@formatjs/intl-pluralrules/should-polyfill';

async function polyfillIntlPluralrules(locale: string) {
    if (!shouldPolyfillIntlPluralrules()) {
        return;
    }
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-pluralrules/polyfill');

    switch (locale) {
        case 'cs':
        default:
            await import('@formatjs/intl-pluralrules/locale-data/cs');
            break;
        case 'en':
            await import('@formatjs/intl-pluralrules/locale-data/en');
            break;
        case 'pl':
            await import('@formatjs/intl-pluralrules/locale-data/pl');
            break;
    }
}

polyfillIntlGetcanonicallocales();
polyfillIntlLocale();
polyfillIntlPluralrules(document.documentElement?.lang || 'cs');
